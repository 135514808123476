<template>
  <div>
    <div v-if="artworkLoading" class="text-center">
      <v-progress-circular indeterminate color="primary" :size="70"></v-progress-circular>
    </div>
    <div v-for="(item, i) in response" :key="i">
      <v-card style="width: 800px" elevation="8" class="my-8 mx-auto">
        <h3 class="pl-4 mt-2 mb-2">Artist: {{ item.artist }}</h3>

        <v-divider></v-divider>
        <v-row v-for="(a, i) in item.albums" :key="i" class="my-4" no-gutters>
          <v-col>
            <v-row justify="start" align="center">
              <v-col cols="1">
                <v-avatar class="ml-2 my-2">
                  <v-img :src="itemImg(a)" :alt="a.picture"></v-img>
                </v-avatar>
              </v-col>
              <v-divider class="mx-2" vertical></v-divider>
              <v-col cols="auto"> {{ a.album }} </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Artwork",
  data: () => ({}),
  methods: {
    async getTracks() {
      this.$store.dispatch("admin/getTracks");
    },
    itemImg(item) {
      return this.$store.getters.itemImg(item);
    },
  },
  computed: {
    response() {
      return this.$store.state.admin?.response;
    },
    artworkLoading() {
      return this.$store.state.admin?.artworkLoading;
    },
  },
  mounted() {
    this.getTracks();
  },
};
</script>
