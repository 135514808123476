<template>
  <v-sheet width="800">
    <v-text-field v-model="artistQuery" label="Artist Name" outlined dense />
    <v-list v-if="results.length" rounded :loading="loading">
      <v-list-item
        v-for="result in results"
        style="cursor: pointer"
        :key="result.artist"
        @click="!selectedArtist ? (selectedArtist = result.artist) : {}"
      >
        {{ result.artist }} - {{ result.count }} tracks available
      </v-list-item>
      <v-dialog v-model="dialog" width="500" hide-overlay>
        <v-card>
          <v-toolbar color="primary" dark class="text-h5"> Set Featured Artist </v-toolbar>

          <v-card-text class="pa-4">
            <h3>Artist Chosen for Feature: {{ selectedArtist }}</h3>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="getFeaturedArtist"> Yes, I'm Sure </v-btn>
            <v-btn color="primary" text @click="selectedArtist = null"> Absolutely Not! </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list>
  </v-sheet>
</template>

<script>
import debounce from "lodash.debounce";

export default {
  name: "SetFeaturedArtist",

  data: () => ({
    success: false,
    loading: false,
    selectedArtist: null,
  }),

  watch: {
    artistQuery(val) {
      val.length > 2 && this.debounceSearch();
    },
  },

  computed: {
    dialog: {
      get: function () {
        return !!this.selectedArtist;
      },
      set: function (artist) {
        this.selectedArtist = artist || null;
      },
    },
    artistQuery: {
      get: function() {return this.$store.state.admin.artistQuery;},
      set: function(val) {
        this.$store.dispatch('admin/setArtistQuery', val)
      }
    },
    results() {
      return this.$store.state.admin?.results;
    }
  },

  methods: {
    async getFeaturedArtist() {
      if (!this.selectedArtist) return;

      this.loading = true;
      this.$store.dispatch("admin/getFeaturedArtist", this.selectedArtist);
      this.loading = false;
      this.selectedArtist = null;
    },
    async searchForArtist() {
      this.loading = true;
      this.$store.dispatch("admin/searchForArtist");
      this.loading = false;
    },
    debounceSearch: debounce(function () {
      this.searchForArtist();
    }, 300),
  },
};
</script>
