<template>
  <div>
    <div>
      <v-card class="mx-auto mt-8 mb-8" max-width="600" max-height="800" elevation="2">
        <v-card-text class="px-16">
          <v-text-field
            autocomplete="false"
            placeholder="Enter title or artist name..."
            v-model="searchTerm"
            ref="searchBar"
            clearable
            @click:clear="clearSearchResults"
          ></v-text-field>
        </v-card-text>
        <v-card-text style="position: relative">
          <v-overlay absolute :value="searchLoading">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-overlay>
          <v-list v-if="searchResults.length" height="600" style="overflow-y: auto">
            <v-list-item
              v-for="item in searchResults"
              :key="item.songid"
              class="song_list"
              @click="!selectedArtist ? (selectedArtist = item) : {}"
              :style="isMobile ? 'cursor: pointer' : ''"
            >
              <v-avatar class="mr-2">
                <v-img :src="itemImg(item)" :alt="item.album"></v-img>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title
                  style="cursor: pointer"
                  id="list_link"
                  :style="isMobile ? 'font-size:0.8rem;' : 'font-size:1.1rem'"
                  >{{ item.title }}</v-list-item-title
                >
                <v-list-item-subtitle
                  :style="isMobile ? 'font-size:0.8rem;' : 'font-size:1.1rem'"
                  >{{ item.artist | separate }}</v-list-item-subtitle
                >
                <v-list-item-subtitle style="cursor: pointer" class="text-caption">{{
                  item.album
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-dialog v-model="dialog" width="500" hide-overlay>
              <v-card>
                <v-toolbar color="primary" dark class="text-h5">
                  Delete Song From Library
                </v-toolbar>

                <v-card-text class="pa-4">
                  <h3>Artist: {{ selectedArtist?.artist }}</h3>
                  <p>Title: {{ selectedArtist?.title }}</p>
                  <p>Ablum: {{ selectedArtist?.album }}</p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="deleteSong"> Yes, I'm Sure </v-btn>
                  <v-btn color="primary" text @click="selectedArtist = null">
                    Absolutely Not!
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list>
          <v-list
            v-else-if="hasSearched && !searchLoading && searchTermSnapshot !== ''"
            height="600"
            style="overflow-y: auto"
          >
            <v-list-item class="song_list">
              <v-list-item-content>
                <v-list-item-title :style="isMobile ? 'font-size:0.8rem;' : 'font-size:1.1rem'"
                  >No results found for '{{ searchTermSnapshot }}'</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>

      <v-snackbar
        timeout="3000"
        :color="deleteResponse?.code === 200 ? 'success' : 'warning'"
        v-model="snackbar"
      >
        <div>
          <div class="white--text text-h5">{{ deleteResponse?.message }}</div>
        </div>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import debounce from "lodash.debounce";

export default {
  name: "DeleteSong",
  data: () => ({
    snackbar: false,
    searchTermSnapshot: "",
    selectedArtist: null,
  }),

  watch: {
    searchLoading(val) {
      if (val) {
        this.$refs.searchBar.blur();
      }
    },
    searchTerm(val) {
      if (!val) {
        this.searchResults = [];
      } else if (val.length >= 3) {
        this.search(val);
      }
    },
    deleteResponse(val) {
      if (val) {
        this.snackbar = true;
        setTimeout(() => {
          this.closeSnackbar();
        }, 4000);
      }
    },
  },
  computed: {
    deleteResponse() {
      return this.$store.state.admin.deleteResponse;
    },
    dialog: {
      get: function () {
        return !!this.selectedArtist;
      },
      set: function (item) {
        this.selectedArtist = item || null;
      },
    },
    hasSearched() {
      return this.$store.state.songRequests.hasSearched;
    },
    searchResults: {
      get() {
        return this.$store.state.songRequests.searchResults;
      },
      set(val) {
        this.$store.dispatch("songRequests/setSearchResults", val);
      },
    },
    searchTerm: {
      get() {
        return this.$store.state.songRequests.searchTerm;
      },
      set(searchTerm) {
        this.$store.dispatch("songRequests/setSearchTerm", searchTerm);
      },
    },
    searchLoading() {
      return this.$store.state.songRequests.searchLoading;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    clearSearchResults() {
      this.searchTerm = "";
      this.searchResults = [];
      this.searchTermSnapshot = "";
    },
    itemImg(item) {
      return this.$store.getters.itemImg(item);
    },
    closeSnackbar() {
      this.snackbar = false;
      this.deleteResponse = null;
      this.dialog = false;
    },
    fetchSongs() {
      this.searchTermSnapshot = this.searchTerm;
      this.$store.dispatch("songRequests/fetchSongs", {
        term: this.searchTerm,
        admin: true,
      });
    },
    async deleteSong() {
      if (!this.selectedArtist) return;
      this.$store.dispatch("admin/deleteSong", this.selectedArtist?.trackid );
    },
  },
  mounted() {
    this.search = debounce(this.fetchSongs.bind(this), 1000);
  },
};
</script>

<style lang="scss" scoped>
.song_list {
  background: #eee;

  &:first-child {
    border-top: 1px solid black;
  }

  &:nth-child(even) {
    background: #f8f8f8;
  }

  &:hover {
    background: #dbdbdb;
  }
}

#list_link {
  transition: all 0.5 ease-in-out;

  &:hover {
    color: blue !important;
    font-size: 1.1em;
  }
}
</style>
