<template>
  <div>
    <v-list v-if="reports.length">

        <v-list-item v-for="(item, index) in reports" :key="index" @click="selectReport(item)">
            <v-list-item-action>
              <v-checkbox
                :input-value="report.filename === item.filename"
                color="primary"
              ></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Filename: {{item.filename}}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
    <v-btn
      class="text-center"
      color="blue darken-2"
      @click="downloadReport(report)"
      dark
      v-if="reports.length"
      >Download Report</v-btn
    >
    <h3 v-if="!reports.length" class="text-center">No Reports Avaliable.</h3>
  </div>
</template>

<script>
export default {
  name: "Report",
  data: () => ({
    report: {},
  }),
  computed: {
    reports() {
      return this.$store.state.admin.reportList;
    }
  },
  methods: {
    selectReport(item) {
      this.report = item
    },
    listReports() {
      this.$store.dispatch("admin/getReportList");
    },
    downloadReport(item) {
      this.$store.dispatch('admin/downloadReport', item)
    },
  },
  mounted() {
    this.listReports();
  },
};
</script>
