<template>
  <v-container fluid style="height: 100%">
    <v-row style="height: 100%">
      <v-col cols="3" py="8">
        <v-sheet color="teal lighten-3" style="height: 100%">
          <div class="text-center text-h3 mx-4">Admin Menu</div>
          <v-btn small dark color="blue darken-2" class="mb-2 ml-2" @click="logout">Logout</v-btn>
          <v-divider class="mb-4"></v-divider>
          <v-row v-for="item in menuButtons" :key="item.name" class="pl-4"
            ><v-col>
              <v-btn :x-small="isMobile" dark color="blue darken-4" @click="choice = item.name">{{
                item.text
              }}</v-btn></v-col
            ></v-row
          >
        </v-sheet>
      </v-col>
      <v-col cols="8">
        <v-card>
          <component :is="adminComponent" />
          <!-- <Artwork v-if="choice === 'artwork'" />
          <SetFeaturedArtist v-if="choice === 'featured'" />
          <DeleteSong v-if="choice === 'delete'" />
          <Reports v-if="choice === 'reports'" />
          <EncoderChart v-if="choice === 'encoders'" /> -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Artwork from "@/components/admin/Artwork.vue";
import DeleteSong from "@/components/admin/DeleteSong.vue";
import Reports from "@/components/admin/Reports.vue";
import SetFeaturedArtist from "@/components/admin/SetFeaturedArtist.vue";
import EncoderChart from "../components/admin/EncoderChart.vue";

export default {
  name: "AdminView",

  components: {
    Artwork,
    SetFeaturedArtist,
    DeleteSong,
    Reports,
    EncoderChart,
  },

  data: () => ({
    choice: null,
    menuButtons: [
      {
        name: "artwork",
        text: "Check For Missing Artwork",
      },
      {
        name: "featured",
        text: "Set Featured Artist",
      },
      {
        name: "deleteSong",
        text: "Delete Song From Library",
      },
      {
        name: "reports",
        text: "Reporting",
      },
      {
        name: "encoders",
        text: "Listener Chart",
      },
    ],
    // componentChoice: null
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    adminComponent() {
      // let component;
      switch (this.choice) {
        case "artwork":
          return Artwork;
        // return (component = Artwork);
        case "featured":
          return SetFeaturedArtist;
        // return (component = Featured);
        case "deleteSong":
          return DeleteSong;
        // return (component = DeleteSong);
        case "reports":
          return Reports;
        // return (component = Reports);
        default:
          return EncoderChart;
        // component = EncoderChart;
      }
      // return component;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Home" });
    },
  },
  mounted() {
    if (localStorage.getItem("authToken")) {
      return;
    } else {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>
