<template>
  <div>
    <div>
      <v-select :items="availableQualities" v-model="selectedQuality" />
    </div>

    <canvas id="listenersChart"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import apiUrl from "../../config/urlconfig";
import axios from "axios";

export default {
  name: "EncoderChart",
  data: () => ({
    selectedQuality: "Average",
    chart: null,
    encoders: [],
    bitrates: [],
    availableQualities: ["Average", "Combine"],
  }),
  watch: {
    selectedQuality() {
      this.renderChart();
    },
  },
  mounted() {
    this.getEncoders();
  },
  methods: {
    renderChart() {
      if (this.chart) {
        this.chart.destroy();
      }
      const calculateListenerAverages = (logsArr) => {
        const bucketSize = 10 * 60 * 1000; // 10 minutes in milliseconds
        const averages = logsArr.reduce((acc, entry) => {
          const timestamp = entry.timestamp;
          const listeners = parseInt(entry.listeners);
          const bucketIndex = Math.floor(timestamp / bucketSize) * bucketSize;

          if (!acc[bucketIndex]) {
            acc[bucketIndex] = {
              totalListeners: 0,
              count: 0,
            };
          }

          acc[bucketIndex].totalListeners += listeners;
          acc[bucketIndex].count++;

          return acc;
        }, {});

        // Calculate averages for each bucket and create array of objects
        const result = Object.keys(averages).map((bucketIndex) => {
          const { totalListeners, count } = averages[bucketIndex];
          const average = totalListeners / count;
          return { [bucketIndex]: average };
        });

        return result;
      };

      const chartDataMapper = (chartEl) => {
        if (this.selectedQuality === "Average") {
          return this.availableQualities.reduce((total, qualityKey) => {
            total += chartEl?.[qualityKey] || 0;
            return total;
          }, 0);
        } else {
          return chartEl?.[this.selectedQuality] || 0;
        }
      };

      const ctx = document.getElementById("listenersChart").getContext("2d");

      const chartData = this.encoders.reduce((acc, { logs, name, active }) => {
        if (active) {
          const reducedTimestampArr = calculateListenerAverages(logs);

          Object.values(reducedTimestampArr).forEach((val) => {
            const dumb = Object.entries(val);
            const timestamp = dumb[0][0];
            const totalListeners = Math.round(dumb[0]?.[1] || 0);
            const foundTimestampIndex = acc.findIndex((el) => el.label === timestamp);
            if (foundTimestampIndex === -1) {
              acc.push({ [name]: totalListeners, label: timestamp });
            } else {
              acc[foundTimestampIndex] = { ...acc[foundTimestampIndex], [name]: totalListeners };
            }
          });
        }
        return acc;
      }, []);

      const getRandomColor = () => {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      };

      const multipleDatasets = () => {
        let datasets = [];
        for (let key in chartData[0]) {
          if (key !== "label") {
            let dataset = {
              label: key,
              data: chartData.map((obj) => obj[key]),
              color: getRandomColor(),
              backgroundColor: "rgba(0, 0, 255, 0.1)",
              borderWidth: 1,
              fill: false,
            };
            datasets.push(dataset);
          }
        }
        if (this.selectedQuality === "Combine") {
          console.log(datasets);
          return datasets;
        }
        return [
          {
            label: this.selectedQuality,
            data: chartData.map(chartDataMapper),
            borderColor: "blue",
            backgroundColor: "rgba(0, 0, 255, 0.1)",
            borderWidth: 1,
          },
        ];
      };

      this.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: chartData.map((el) => new Date(parseInt(el.label)).toLocaleString()),
          datasets: multipleDatasets(),
        },
        options: {
          scales: {
            x: {
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
                labelString: "Time",
              },
            },

            y: {
              display: true,
              title: {
                display: true,
                text: "Number of Listeners",
              },
              min: 0,
            },
          },
        },
      });
    },
    async getEncoders() {
      const url = `${apiUrl}/api/encoders`;
      try {
        const response = await axios.post(url);
        if (response?.data && Array.isArray(response.data)) {
          this.encoders = response.data;
          this.availableQualities = [
            "Average",
            "Combine",
            ...response.data.filter((el) => el.active).map((el) => el.name),
          ];
        }
        this.renderChart();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
